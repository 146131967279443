import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Fflp from "src/components/fflp"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Ctadisclosureint from "src/components/ctadisclosureint"

const chromaData = import("./data/data.json");


export default function dutchChroma() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         height: 100vh;
         width: 100%;
         min-width: 728px;
         min-height: 700px;
         background: #E1EEF8;
         background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
         font-family: 'Roboto', sans-serif;
       }

       #fflp-module--fflp {
         height: 100vh;
       }

       #fflp-module--fflp .fflp-module--main {
         margin: 100px auto 0;
       }

       #ctadisclosureff-module--ctadisclaimer {
        max-width: 900px;
        margin: 0 auto;
       }

       a.ctadisclosureff-module--bold {
        margin-left: 4px;
       }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Fflp data={chromaData} language='dutch'>
        <Ctadisclosureint data={chromaData} language="dutchXpi"></Ctadisclosureint>       
      </Fflp>
      </section>
    </HomepageLayout>
  )
}
